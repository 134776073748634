import { mapActions, mapGetters } from 'vuex';
import efficiency from '../CompanyStatistic/Efficiency/index.vue';

export default {
  name: 'PlacementEfficiency',
  i18n: {
    messages: {
      ru: {
        'Всі акаунти': 'Все аккаунты',
      },
      uk: {
        'Всі акаунти': 'Всі акаунти',
      },
    },
  },
  components: {
    efficiency,
  },
  data() {
    return {
      selectedOption: undefined,
    };
  },
  computed: {
    ...mapGetters({
      userData: 'cabinet/userInfo/userData',
      getAccounts: 'accountSwitches/getAccounts',
    }),
    selectOptions() {
      let options = [];
      const accounts = Object.values(this.getAccounts);

      this.selectedOption = String(this.userData.id);

      if (accounts.length) {
        options = Object.values(this.getAccounts).map((account) => ({
          value: String(account.user_id),
          title: `${account.name} ID${account.user_id}`,
        }));
      }

      if (options.length > 1) {
        options.unshift({
          value: Object.keys(this.getAccounts).join(','),
          title: this.$t('Всі акаунти'),
        });
      }

      return options;
    },
  },
  beforeMount() {
    this.selectedOption = String(this.userData?.id || '');
    this.fetchAllUsers();
  },
  methods: {
    ...mapActions({
      fetchProAccountStatistic: 'proAccount/fetchProAccountStatistic',
      fetchAllUsers: 'accountSwitches/fetch',
    }),
    onChangeOption(event) {
      this.selectedOption = event.target.value;
    },
  },
};
